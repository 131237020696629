import React, { useMemo } from 'react'
import { Form } from '@damen/ui'
import { useRefinementList } from 'react-instantsearch-core'
import { FacetProps } from './types'
import { DropdownFilterWrapper } from './styles'

const DropdownFacet: React.FC<React.PropsWithChildren<FacetProps>> = ({
  type,
  label,
  trueTranslation,
  falseTranslation,
}) => {
  const { items, refine } = useRefinementList({
    attribute: type,
    sortBy: ['isRefined', 'name:asc'],
    limit: 50,
  })

  const initialValues = useMemo(
    () =>
      items
        .filter((x) => x.isRefined)
        .map((x) => {
          return x.value
        }),
    [items],
  )

  const mappedItems = useMemo(
    () =>
      items.map((x) => {
        const translatedLabel = () => {
          // When we want to filter on a boolean attribute in Algolia, the default filter options are true and false.
          // We would like them to be more meaningful and so we added an option to pass a translation for true and false values.
          switch (x.value) {
            case 'true':
              if (trueTranslation) {
                return trueTranslation
              }
              return x.value

            case 'false':
              if (falseTranslation) {
                return falseTranslation
              }
              return x.value

            default:
              return x.value
          }
        }

        return {
          ...x,
          label: `${translatedLabel()} (${x.count})`,
        }
      }),
    [falseTranslation, items, trueTranslation],
  )

  const onSelect = (values: string[]) => {
    const combinedValues = [...initialValues, ...values]
    combinedValues.forEach((value) => refine(value))
  }

  return (
    <DropdownFilterWrapper key={type}>
      <Form.MultiSelect
        placeholder={label}
        options={mappedItems}
        selectedOptions={initialValues}
        readOnly={items.length === 0}
        onSelect={onSelect}
        background="grey"
        border={false}
      />
    </DropdownFilterWrapper>
  )
}

export default DropdownFacet
