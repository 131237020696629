import React, { useMemo } from 'react'
import { useRefinementList } from 'react-instantsearch-core'
import { SearchFilters } from '@/components/UI'
import { FacetProps } from './types'
import { CheckboxFilterWrapper } from './styles'

const CheckboxFacet: React.FC<React.PropsWithChildren<FacetProps>> = ({
  type,
  label,
  trueTranslation,
  falseTranslation,
  showFalseValue,
  expandText,
  collapseText,
}) => {
  const { items, refine } = useRefinementList({ attribute: type, sortBy: ['isRefined', 'name:asc'], limit: 50 })
  const initialValues = useMemo(
    () =>
      items
        .filter((x) => x.isRefined)
        .map((x) => {
          return x.value
        }),
    [items],
  )

  const onChange = (value: string) => {
    refine(value)
  }

  const options = useMemo(
    () =>
      items
        .filter((item) => (item.value === 'false' ? showFalseValue : true))
        .map((x) => {
          const translatedLabel = () => {
            // When we want to filter on a boolean attribute in Algolia, the default filter options are true and false.
            // We would like them to be more meaningful and so we added an option to pass a translation for true and false values.
            switch (x.value) {
              case 'true':
                if (trueTranslation) {
                  return trueTranslation
                }
                return x.value

              case 'false':
                if (falseTranslation) {
                  return falseTranslation
                }
                return x.value

              default:
                return x.value
            }
          }

          return {
            ...x,
            label: translatedLabel(),
          }
        }),
    [falseTranslation, showFalseValue, items, trueTranslation],
  )

  if (options.length === 0) {
    return null
  }

  const enableExpandText = expandText.length > 0

  const mappedFacets: any = enableExpandText
    ? [
        {
          key: type,
          expandText: `${expandText} ${label.toLowerCase()}`,
          initiallyOpen: true,
          initialValues: [...initialValues],
          isCollapsible: true,
          options,
          title: label,
          onChange,
          initialItemCount: 4,
          optionsCollapseText: collapseText ? `${collapseText} ${label.toLowerCase()}` : undefined,
        },
      ]
    : [
        {
          key: type,
          initiallyOpen: true,
          initialValues: [...initialValues],
          isCollapsible: true,
          options,
          title: label,
          onChange,
          initialItemCount: options.length,
        },
      ]

  return (
    <CheckboxFilterWrapper>
      <SearchFilters facets={mappedFacets} />
    </CheckboxFilterWrapper>
  )
}

export default CheckboxFacet
