import React, { useState } from 'react'
import { Form, Icon, Button } from '@damen/ui'
import { useNumericMenu } from 'react-instantsearch-core'
import { FacetProps } from './types'
import { DropdownFilterWrapper } from './styles'
import CustomSelect, { CustomDropdownInputWrapper, CustomDropdownTextWrapper } from './customDropdown'

const DropdownFacet: React.FC<React.PropsWithChildren<FacetProps>> = ({
  type,
  label,
  unit,
}) => {
  const [numericValue, setNumericValue] = useState('')
  const { refine: refineNumeric } = useNumericMenu({
    attribute: type,
    // Items is required in the hook but we currently don't use it
    items: [{ label: 'More than' }],
  })

  const onChange = (e: any) => {
    setNumericValue(e.target.value)
  }
  const formattedLabel = unit ?? ''
  return (
    <DropdownFilterWrapper>
      <CustomSelect background="grey" border={false} placeholder={label}>
        <CustomDropdownInputWrapper>
          <Form.Input name={`Filter${type}`} value={numericValue} onChange={onChange} type="number" />
        </CustomDropdownInputWrapper>
        <CustomDropdownTextWrapper>{formattedLabel}</CustomDropdownTextWrapper>
        <Button.Default
          text=""
          hideText
          type="button"
          onClick={() => {
            // This format is used by the hook when rendering the items passed there
            // (in stead of us rendering an input with a button)
            refineNumeric(`%7B%22start%22:${numericValue}%7D`)
          }}
          iconSize={20}
          icon={Icon.LeftChevron}
        />
      </CustomSelect>
    </DropdownFilterWrapper>
  )
}

export default DropdownFacet
