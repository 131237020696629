import { Paragraph, theme } from '@damen/ui'
import styled from 'styled-components'

const FilterButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: 24px;
  margin: ${theme.layout.spacing};
  background: ${theme.colors.greyAccentLight};
  color: ${theme.colors.marineBlack};
  padding: ${theme.layout.spacing} ${theme.layout.spacingM};
  line-height: 0;
`

const Label = styled.span`
  margin-left: ${theme.layout.spacing};
  font-size: ${theme.typography.fontSizeLabel}px;
  font-weight: ${theme.typography.fontWeightMedium};
`

const SelectedFilters = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-flow: row wrap;
  margin: -${theme.layout.spacing};
`

const SelectedFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${theme.layout.gridWidthInner};
  width: 100%;
  margin-top: ${theme.spacing.x4}px;

  @media ${theme.legacyMediaQueries.md} {
    flex-direction: row;
    margin-top: ${theme.spacing.x6}px;
  }
`

const FiltersWrapperMargins = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${theme.layout.gridWidthInner};
  width: 100%;
  margin: 0 0 ${theme.layout.spacingM} 0;
  min-height: 26px;

  @media ${theme.legacyMediaQueries.md} {
    flex-direction: row;
  }

  @media ${theme.legacyMediaQueries.xs} {
    width: 100%;
    margin: 0 0 ${theme.layout.spacingM} 0;
  }
`

const ResultsText = styled(Paragraph.Default)`
  white-space: nowrap;
  margin: 0 ${theme.layout.spacingM} ${theme.layout.spacing} 0;
  font-weight: ${theme.typography.fontWeightMedium};
  line-height: 26px;

  @media ${theme.legacyMediaQueries.md} {
    margin: 0 ${theme.layout.spacingM} 0 0;
  }
`

export { FilterButton, FiltersWrapperMargins, Label, ResultsText, SelectedFiltersWrapper, SelectedFilters }
