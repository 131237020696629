import React, { useMemo } from 'react'
import { InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch-core'
import type { InstantSearchProps } from '../types'
import { createDefaultAlgoliaSearchClient } from '@/lib/algolia'
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs'
import singletonRouter from 'next/router'


const InstantSearchComponent: React.FC<React.PropsWithChildren<InstantSearchProps>> = ({ indexName, children }) => {
  const currentSearchClientInstance = useMemo(() => createDefaultAlgoliaSearchClient(), [])

  return (
    <InstantSearchSSRProvider>
      <InstantSearch
        indexName={indexName}
        searchClient={currentSearchClientInstance}
        routing={{
          router: createInstantSearchRouterNext({
            singletonRouter,
            serverUrl: '',
            routerOptions: {
              cleanUrlOnDispose: false,
            }
          }),
        }}
        future={{
          preserveSharedStateOnUnmount: false,
        }}
      >
        {children}
      </InstantSearch>
    </InstantSearchSSRProvider>
  )
}

export default InstantSearchComponent
