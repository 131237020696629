import React, { useEffect, useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { Icon, theme } from '@damen/ui'

type Background = 'grey' | 'white'

export interface Props {
  background?: Background
  border?: boolean
  placeholder?: string
  readOnly?: boolean
}

const CustomSelect: React.FC<React.PropsWithChildren<Props>> = ({
  background = 'white',
  border = true,
  placeholder = 'MultiSelect',
  readOnly = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const optionsWrapperRef = useRef<HTMLDivElement>(null)
  const selectRef = useRef<HTMLLabelElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        optionsWrapperRef.current &&
        selectRef.current &&
        !optionsWrapperRef.current.contains(event.target) &&
        !selectRef.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [selectRef])

  const handleOnSelectClick = (): void => {
    if (readOnly) {
      return null
    }
    setIsOpen((curr) => !curr)
  }

  const handleCloseOnBlur = () => {
    setIsOpen((curr) => !!curr)
  }

  return (
    <Container isOpen={isOpen}>
      <Selector
        background={background}
        border={border}
        htmlFor="filter-select"
        isOpen={isOpen}
        readOnly={readOnly}
        ref={selectRef}
        tabIndex={readOnly ? -1 : 0}
        onBlur={handleCloseOnBlur}
        onClick={handleOnSelectClick}
      >
        <Placeholder readOnly={readOnly}>{placeholder}</Placeholder>
        <IconWrapper isOpen={isOpen}>
          <Icon.CaretDown fill={readOnly ? theme.colors.greyAccentDark : theme.colors.marineBlack} />
        </IconWrapper>
      </Selector>
      <Options isOpen={isOpen} ref={optionsWrapperRef}>
        {children}
      </Options>
    </Container>
  )
}

const fadeInDown = keyframes`
  from {
    transform: translate3d(0, -20px, 0);
  }

  to {
    transform: none;
  }
`

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  position: relative;
  width: '100%';
  z-index: ${({ isOpen }) => (isOpen ? `4` : '1')};
  max-width: 100%;
`

const Selector = styled.label<{
  background: string
  border: boolean
  isOpen: boolean
  readOnly: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  background-color: ${({ background }) => (background === 'grey' ? theme.colors.blueIce : theme.colors.white)};
  border-radius: 4px;
  border: ${({ border }) => (border ? `1px solid ${theme.colors.greyDark}` : 'none')};
  cursor: ${({ readOnly }) => (!readOnly ? `pointer` : `not-allowed`)};
  transition: background ${theme.timing.fast};

  ${({ background, readOnly }) =>
    !readOnly
      ? `
      &:focus {
        outline: ${theme.accessibility.outline};
      }
      &:hover {
        background-color: ${background === 'grey' ? theme.colors.greyAccentLight : theme.colors.silverGrey100};
      }
    `
      : `
  :focus {
    outline: none;
  }`};

  ${({ isOpen, background }) =>
    isOpen &&
    `
      border: 0;
      background-color: ${background === 'grey' ? theme.colors.greyAccentLight : theme.colors.silverGrey100};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;`};
`

const IconWrapper = styled.span.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  display: flex;
  width: auto;
  margin-right: ${theme.layout.spacingM};
  color: ${({ isOpen }) => isOpen && theme.colors.accentLightBlue};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: ${theme.timing.default} ease;
`

const Placeholder = styled.p.withConfig({
  shouldForwardProp: (prop) => !['readOnly'].includes(prop),
})<{ readOnly: boolean }>`
  margin-left: ${theme.layout.spacingM};
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightMedium};
  color: ${({ readOnly }) => (readOnly ? theme.colors.silverGrey500 : theme.colors.marineBlack)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
`

const Options = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  animation: ${fadeInDown} ${theme.timing.fast};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.whiteOnWhite.three};
  flex-direction: row;
  position: absolute;
  width: 100%;
  max-height: 256px;
  min-width: 328px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
  overflow-y: auto;
  z-index: 4;
  padding: ${theme.layout.spacingM};
  opacity: 1;

  :hover {
    border-top: none;
  }
`

export const Error = styled.span`
  width: 100%;
  margin: 8px 0 0 19px;
  line-height: 1;
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: 500;
  color: ${theme.colors.blue};
`

export const CustomDropdownInputWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 48px;
  margin-right: ${theme.layout.spacing};
`

export const CustomDropdownTextWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`

export default CustomSelect
