// Ref: https://www.algolia.com/doc/api-reference/widgets/sort-by/react-hooks/#hook-example
import React, { useEffect, useState } from 'react'
import { Form } from '@damen/ui'
import { useCurrentRefinements, useSortBy } from 'react-instantsearch-core'
import { SortWrapper } from './styles'
import { SortProps } from './types'
import { SortByItem } from '../types'

const Sort: React.FC<React.PropsWithChildren<SortProps>> = ({
  sortOptions,
  sortPlaceholder,
  initialSort,
  sortByOptions4thSpecification,
}) => {
  const allSortByOptions = [...sortOptions, ...sortByOptions4thSpecification]
  const { items } = useCurrentRefinements()
  const { refine, options, currentRefinement } = useSortBy({ items: allSortByOptions })
  const initialSortOption = options.find(({ value }) => currentRefinement === value)
  const [sortByOptions, setSortByOptions] = useState<SortByItem[]>(sortOptions)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasFiltered, setHasFiltered] = useState<boolean>(false)
  const [hasSorted, setHasSorted] = useState<boolean>(false)

  useEffect(() => {
    if (sortByOptions4thSpecification.length > 0) {
      const filteredItems = items.find((r) => r.refinements.find((a) => a.attribute === 'range'))

      if (filteredItems?.refinements.length === 1 && filteredItems.refinements[0]?.count > 1) {
        const vesselType = filteredItems.refinements[0]?.value
        const sortByOptions4thSpecificationFilterd = sortByOptions4thSpecification.filter((x) =>
          x.ranges.find((r) => r.name === vesselType),
        )
        setSortByOptions([...sortOptions, ...sortByOptions4thSpecificationFilterd])
        setIsLoading(false)
        setHasFiltered(true)
      }

      if (filteredItems?.refinements.length > 1 && hasSorted) {
        setSortByOptions(sortOptions)
        if (!sortOptions.find((x) => x.value === currentRefinement)) {
          refine(sortOptions[0].value)
        }
        setIsLoading(false)
        setHasFiltered(true)
      }

      if (!filteredItems?.refinements && isLoading) {
        setIsLoading(false)
      }
    }
  }, [currentRefinement, hasSorted, isLoading, items, refine, sortByOptions4thSpecification, sortOptions])

  useEffect(() => {
    const filteredItems = items.find((r) => r.refinements.find((a) => a.attribute === 'range'))
    if (!filteredItems?.refinements && hasFiltered) {
      setSortByOptions(sortOptions)
      if (!sortOptions.find((x) => x.value === currentRefinement)) {
        refine(sortOptions[0].value)
      }
    }
  }, [currentRefinement, hasFiltered, items, refine, sortOptions])

  const onSelect = (option: string | number) => {
    if (currentRefinement !== option.toString()) {
      setHasSorted(true)
      refine(option.toString())
    }
  }

  if (sortByOptions4thSpecification.length > 0 && isLoading) {
    return null
  }

  return (
    <SortWrapper>
      <Form.Select
        placeholder={sortPlaceholder}
        initialValue={initialSort ?? initialSortOption?.value}
        options={sortByOptions}
        readOnly={false}
        onSelect={onSelect}
      />
    </SortWrapper>
  )
}

export default Sort
